import { ACLResources as CapRelResources } from 'app/modules/capacity-release/constants';
import { EPermissionOption, EUserType, NavConfigAcl } from 'app/store/auth/model/models';

export interface NavConfig {
  id: string;
  label: string;
  icon?: string;
  route?: string;
  featureName?: string;
  href?: string; // Don't use both route and href at the same time
  skipAclCheck?: boolean; // If true, this item will be shown instead of hidden when no roles exist
  subMenuItems?: NavConfig[];
  acl?: NavConfigAcl;
}

export const upperNavMenuConfigs: NavConfig[] = [
  {
    id: 'home-sidebar-item',
    label: 'Home',
    route: '/home',
    icon: 'icon-home',
    skipAclCheck: true,
  },
  {
    id: 'nominationsModel',
    label: 'Nominations',
    icon: 'icon-nominations',
    subMenuItems: [
      {
        id: 'Nomination',
        label: 'Nomination',
        route: '/nominations',
        acl: {
          resource: '/nominations/nomination',
          accessLevel: EPermissionOption.ReadOnly,
        },
      },
      {
        id: 'BlockMatching',
        label: 'Block Matching',
        route: '/nominations/block-matching',
        acl: {
          resource: '/nominations/block-matching',
          accessLevel: EPermissionOption.ReadOnly,
          userType: EUserType.Internal,
        },
      },
      {
        id: 'Confirmation',
        label: 'Confirmation',
        route: '/nominations/confirmations',
        acl: {
          resource: '/nominations/confirmations',
          accessLevel: EPermissionOption.ReadOnly,
        },
      },
      {
        id: 'Scheduling-Engine',
        label: 'Scheduling Engine',
        route: '/nominations/scheduling-engine',
        acl: {
          resource: '/nominations/scheduling-engine',
          accessLevel: EPermissionOption.ReadOnly,
          userType: EUserType.Internal,
        },
      },
      {
        id: 'Scheduled-Quantity',
        label: 'Scheduled Quantity',
        route: '/nominations/scheduled-quantity',
        acl: {
          resource: '/nominations/scheduled-quantity',
          accessLevel: EPermissionOption.ReadOnly,
        },
      },
      {
        id: 'Scheduled-Quantity-for-Operator',
        label: 'Scheduled Quantity for Operator',
        route: '/nominations/sched-qty-operator',
        acl: {
          resource: '/nominations/sched-qty-operator',
          accessLevel: EPermissionOption.ReadOnly,
        },
      },
      {
        id: 'Nominations-Details',
        label: 'Nominations Details',
        acl: {
          resource: '/nominations/nominations-details',
          accessLevel: EPermissionOption.ReadOnly,
        },
        subMenuItems: [
          {
            id: 'Balancing-Location',
            label: 'Balancing Location',
            route: '/nominations/nominations-details/1',
            acl: {
              resource: '/nominations/nominations-details',
              accessLevel: EPermissionOption.ReadOnly,
            },
          },
          {
            id: 'Balancing-Location-Summary',
            label: 'Balancing Location Summary',
            route: '/nominations/nominations-details/2',
            acl: {
              resource: '/nominations/nominations-details',
              accessLevel: EPermissionOption.ReadOnly,
              userType: EUserType.Internal,
            },
          },
          {
            id: 'Changes-at-Explicit-Locations',
            label: 'Changes at Explicit Confirmation Locations',
            route: '/nominations/nominations-details/13',
            acl: {
              resource: '/nominations/nominations-details',
              accessLevel: EPermissionOption.ReadOnly,
              userType: EUserType.Internal,
            },
          },
          {
            id: 'DPL-Payback-Balancing',
            label: 'DPL Payback Balancing',
            route: '/nominations/nominations-details/3',
            acl: {
              resource: '/nominations/nominations-details',
              accessLevel: EPermissionOption.ReadOnly,
            },
          },
          {
            id: 'EPS-Balancing',
            label: 'EPS Balancing',
            route: '/nominations/nominations-details/12',
            acl: {
              resource: '/nominations/nominations-details',
              accessLevel: EPermissionOption.ReadOnly,
            },
          },
          {
            id: 'Nomination-Activity-Report',
            label: 'Nomination Activity Report',
            route: '/nominations/nominations-details/5',
            acl: {
              resource: '/nominations/nominations-details',
              accessLevel: EPermissionOption.ReadOnly,
            },
          },
          {
            id: 'Nomination-Path-Report',
            label: 'Nomination Path Report',
            route: '/nominations/nominations-details/4',
            acl: {
              resource: '/nominations/nominations-details',
              accessLevel: EPermissionOption.ReadOnly,
            },
          },
          {
            id: 'Park-and-Loan-Balancing',
            label: 'Park and Loan Balancing',
            route: '/nominations/nominations-details/6',
            acl: {
              resource: '/nominations/nominations-details',
              accessLevel: EPermissionOption.ReadOnly,
            },
          },
          {
            id: 'Park-and-Loan-Balancing-Summary',
            label: 'Park and Loan Balancing Summary',
            route: '/nominations/nominations-details/7',
            acl: {
              resource: '/nominations/nominations-details',
              accessLevel: EPermissionOption.ReadOnly,
              userType: EUserType.Internal,
            },
          },
          {
            id: 'Pool-Balancing',
            label: 'Pool Balancing',
            route: '/nominations/nominations-details/8',
            acl: {
              resource: '/nominations/nominations-details',
              accessLevel: EPermissionOption.ReadOnly,
            },
          },
          {
            id: 'Pool-Balancing-Summary',
            label: 'Pool Balancing Summary',
            route: '/nominations/nominations-details/9',
            acl: {
              resource: '/nominations/nominations-details',
              accessLevel: EPermissionOption.ReadOnly,
              userType: EUserType.Internal,
            },
          },
          {
            id: 'Storage-Balancing',
            label: 'Storage Balancing',
            route: '/nominations/nominations-details/10',
            acl: {
              resource: '/nominations/nominations-details',
              accessLevel: EPermissionOption.ReadOnly,
            },
          },
          {
            id: 'Storage-Balancing-Summary',
            label: 'Storage Balancing Summary',
            route: '/nominations/nominations-details/11',
            acl: {
              resource: '/nominations/nominations-details',
              accessLevel: EPermissionOption.ReadOnly,
              userType: EUserType.Internal,
            },
          },
          {
            id: 'Transfer-Balancing',
            label: 'Transfer Balancing',
            route: '/nominations/nominations-details/37',
            acl: {
              resource: '/nominations/nominations-details',
              accessLevel: EPermissionOption.ReadOnly,
            },
          },
          {
            id: 'Transfer-Balancing-Summary',
            label: 'Transfer Balancing Summary',
            route: '/nominations/nominations-details/38',
            acl: {
              resource: '/nominations/nominations-details',
              accessLevel: EPermissionOption.ReadOnly,
            },
          },
        ],
      },
      {
        id: 'Upload-Nominations',
        label: 'Upload Nominations',
        route: '/nominations/nomination-upload',
        acl: {
          resource: '/nominations/nomination-upload',
          accessLevel: EPermissionOption.Edit,
        },
      },
    ],
  },
  {
    id: 'flowingGas',
    label: 'Flowing Gas',
    icon: 'icon-flowing-gas',
    subMenuItems: [
      {
        id: 'Predetermined-Allocation',
        label: 'Pre-determined Allocation',
        route: '/flowing-gas/pda/',
        acl: {
          resource: '/flowing-gas/pda',
          accessLevel: EPermissionOption.ReadOnly,
        },
      },
      {
        id: 'Allocation',
        label: 'Allocation',
        route: '/flowing-gas/allocations/',
        acl: {
          resource: '/flowing-gas/allocations',
          accessLevel: EPermissionOption.ReadOnly,
        },
      },
      {
        id: 'Imbalance',
        label: 'Imbalance',
        acl: {
          resource: '/flowing-gas/imbalance',
          accessLevel: EPermissionOption.ReadOnly,
        },
        subMenuItems: [
          {
            id: 'Authorization-To-Post-Imbalances',
            label: 'Authorization to Post Imbalances',
            route: '/imbalance/authorization-to-post',
            acl: {
              resource: '/imbalance/authorization-to-post',
              accessLevel: EPermissionOption.ReadOnly,
            },
          },
          {
            id: 'Imbalance-Trade',
            label: 'Imbalance Trade',
            route: '/imbalance/imbalance-trade',
            acl: {
              resource: '/imbalance/imbalance-trade',
              accessLevel: EPermissionOption.ReadOnly,
            },
          },
          {
            id: 'Operational-Balancing',
            label: 'Operational Balancing',
            route: '/flowing-gas/operational-balancing',
            acl: {
              resource: '/flowing-gas/balancing-agreement-imbalance',
              accessLevel: EPermissionOption.ReadOnly,
            },
          },
          {
            id: 'Operational-Balancing-Summary',
            label: 'Operational Balancing Summary',
            route: '/imbalance/oba-summary',
            acl: {
              resource: '/imbalance',
              accessLevel: EPermissionOption.ReadOnly,
              userType: EUserType.Internal,
            },
          },
          {
            id: 'Shipper-Activity',
            label: 'Shipper Activity',
            route: '/imbalance/shipper-activity',
            acl: {
              resource: '/imbalance',
              accessLevel: EPermissionOption.ReadOnly,
            },
          },
          {
            id: 'Shipper-Imbalance',
            label: 'Shipper Imbalance',
            route: '/flowing-gas/shipper-imbalance',
            acl: {
              resource: '/flowing-gas/shipper-imbalance',
              accessLevel: EPermissionOption.ReadOnly,
            },
          },
          // Uncomment the below nav items as we add in these additional reports as part of GQSUP-465/588/576
          {
            id: 'Shipper-Pool-Detail-Report',
            label: 'Shipper Pool Detail',
            route: '/flowing-gas/shipper-pool-detail/33',
            acl: {
              resource: '/flowing-gas/shipper-imbalance',
              accessLevel: EPermissionOption.ReadOnly,
            },
          },
          {
            id: 'Shipper-Pool-Summary-Report',
            label: 'Shipper Pool Summary',
            route: '/flowing-gas/shipper-pool-summary/34',
            acl: {
              resource: '/flowing-gas/shipper-imbalance',
              accessLevel: EPermissionOption.ReadOnly,
            },
          },
          {
            id: 'Shipper-Quantity-Daily-Report',
            label: 'Shipper Quantity Detail',
            route: '/flowing-gas/shipper-quantity-detail/35',
            acl: {
              resource: '/flowing-gas/shipper-imbalance',
              accessLevel: EPermissionOption.ReadOnly,
            },
          },
          {
            id: 'Shipper-Quantity-Summary-Report',
            label: 'Shipper Quantity Summary',
            route: '/flowing-gas/shipper-quantity-summary/36',
            acl: {
              resource: '/flowing-gas/shipper-imbalance',
              accessLevel: EPermissionOption.ReadOnly,
            },
          },
        ],
      },
      {
        id: 'Measurement',
        label: 'Measurement',
        acl: {
          resource: '/flowing-gas/measurements',
          accessLevel: EPermissionOption.ReadOnly,
        },
        subMenuItems: [
          {
            id: 'Current-Day-Measurement',
            label: 'Current Day Measurement',
            route: '/flowing-gas/measurement/current-day-measurement',
            acl: {
              resource: '/flowing-gas/measurement/current-day-measurement',
              accessLevel: EPermissionOption.ReadOnly,
            },
          },
          {
            id: 'Gas-Quality',
            label: 'Gas Quality',
            route: '/flowing-gas/measurement/gas-quality',
            acl: {
              resource: '/flowing-gas/measurement/gas-quality',
              accessLevel: EPermissionOption.ReadOnly,
            },
          },
          {
            id: 'Hourly-Flows',
            label: 'Hourly Flows',
            route: '/flowing-gas/measurement/hourly-overruns',
            acl: {
              resource: '/flowing-gas/measurement/hourly-overruns',
              accessLevel: EPermissionOption.ReadOnly,
            },
          },
          {
            id: 'Measurement-Information',
            label: 'Measurement Information',
            route: '/flowing-gas/measurements',
            acl: {
              resource: '/flowing-gas/measurements',
              accessLevel: EPermissionOption.ReadOnly,
            },
          },
          {
            id: 'Measured-Volume-Audit-Statement',
            label: 'Measured Volume Audit Statement',
            route: '/flowing-gas/measurement/measured-volume-audit-statement',
            acl: {
              resource: '/flowing-gas/measurement/measured-volume-audit-statement',
              accessLevel: EPermissionOption.ReadOnly,
            },
          },
        ],
      },
      {
        id: 'Storage',
        label: 'Storage',
        acl: {
          resource: '/storage',
          accessLevel: EPermissionOption.ReadOnly,
        },
        subMenuItems: [
          {
            id: 'Storage-Information',
            label: 'Storage Information',
            route: '/storage/storage-balance',
            acl: {
              resource: '/storage',
              accessLevel: EPermissionOption.ReadOnly,
            },
          },
          {
            id: 'Storage-transfer',
            label: 'Storage Transfer',
            route: '/storage/storage-transfer',
            acl: {
              resource: '/storage/storage-transfer',
              accessLevel: EPermissionOption.ReadOnly,
            },
          },
          {
            id: 'tcetq',
            label: 'TCETQ',
            route: '/storage/storage-tcetq',
            acl: {
              userType: EUserType.Internal,
            },
          },
        ],
      },
      {
        id: 'Park-and-Loan-Information',
        label: 'Park and Loan Information',
        route: '/flowing-gas/park-and-loan',
        acl: {
          resource: '/flowing-gas/park-and-loan',
          accessLevel: EPermissionOption.ReadOnly,
        },
      },
    ],
  },
  {
    id: 'invoicing',
    label: 'Invoicing',
    icon: 'icon-invoicing',
    subMenuItems: [
      {
        id: 'Invoice',
        label: 'Invoice',
        acl: {
          resource: '/invoices',
          accessLevel: EPermissionOption.ReadOnly,
        },
        subMenuItems: [
          {
            id: 'Invoices',
            label: 'Transportation Invoice',
            route: '/invoices',
            acl: {
              resource: '/invoices',
              accessLevel: EPermissionOption.ReadOnly,
            },
          },
          {
            id: 'Invoice-Transactions',
            label: 'Invoice Transactions',
            route: '/invoices/invoice-transactions',
            acl: {
              resource: '/invoices',
              accessLevel: EPermissionOption.ReadOnly,
            },
          },
          {
            id: 'Invoice-Attachments',
            label: 'Invoice Attachments',
            route: '/invoices/invoice-attachments',
            acl: {
              resource: '/invoices/invoice-attachments',
              accessLevel: EPermissionOption.ReadOnly,
              userType: EUserType.Internal,
            },
          },
          {
            id: 'Invoice-Exceptions',
            label: 'Invoice Exceptions',
            route: '/invoices/invoice-exceptions',
            acl: {
              resource: '/invoices/invoice-exceptions',
              accessLevel: EPermissionOption.ReadOnly,
              userType: EUserType.Internal,
            },
          },
          {
            id: 'Quantity-Entitlement-Summary-Report',
            label: 'Quantity Entitlement Summary Report',
            route: '/invoices/quantity-entitlement-summary-report',
            acl: {
              resource: '/invoices/quantity-entitlement-summary-report',
              accessLevel: EPermissionOption.ReadOnly,
            },
          },
          {
            id: 'Supplemental-Report',
            label: 'Supplemental Report',
            route: '/invoices/supplemental-report',
            acl: {
              resource: '/invoices/supplemental-report',
              accessLevel: EPermissionOption.ReadOnly,
              userType: EUserType.Internal,
            },
          },
        ],
      },
      {
        id: 'Statement-of-Account',
        label: 'Statement of Account',
        route: '/invoices/statement-of-account',
        acl: {
          resource: '/invoices/statement-of-account',
          accessLevel: EPermissionOption.ReadOnly,
        },
      },
    ],
  },
  {
    id: 'capacityRelease',
    label: 'Capacity Release',
    icon: 'icon-capacity-release',
    subMenuItems: [
      {
        id: 'Offers',
        label: 'Offers',
        route: '/capacity-release/offers',
        acl: {
          resource: CapRelResources.OFFERS,
          accessLevel: EPermissionOption.ReadOnly,
        },
      },
      {
        id: 'Bids',
        label: 'Bids',
        route: '/capacity-release/bids',
        acl: {
          resource: CapRelResources.BIDS,
          accessLevel: EPermissionOption.ReadOnly,
        },
      },
      {
        id: 'Awards',
        label: 'Awards',
        route: '/capacity-release/awards',
        acl: {
          resource: CapRelResources.AWARDS,
          accessLevel: EPermissionOption.ReadOnly,
        },
      },
      {
        id: 'RecallsReputs',
        label: 'Recalls/Reputs',
        route: '/capacity-release/recalls-reputs',
        acl: {
          resource: CapRelResources.RECALLS_REPUT,
          accessLevel: EPermissionOption.ReadOnly,
        },
      },
      {
        id: 'BidEvaluation',
        label: 'Bid Evaluation',
        route: '/capacity-release/bidevaluation',
        acl: {
          resource: CapRelResources.BID_EVALUATION,
          accessLevel: EPermissionOption.Edit,
          userType: EUserType.Internal,
        },
      },
      {
        id: 'Withdrawals',
        label: 'Withdrawals',
        route: '/capacity-release/withdrawals',
        acl: {
          resource: CapRelResources.WITHDRAWALS,
          accessLevel: EPermissionOption.ReadOnly,
        },
      },
    ],
  },
  {
    id: 'contractsSubMenu',
    label: 'Contracts',
    icon: 'icon-contracts',
    subMenuItems: [
      {
        id: 'Contract-Consolidation-',
        label: 'Contract Consolidation Request',
        route: '/contract-consolidation/new',
        acl: {
          resource: '/contract-consolidation/new',
          accessLevel: EPermissionOption.ReadOnly,
        },
      },
      {
        id: 'Contract-Info',
        label: 'Contract Info',
        route: '/contracts-list',
        acl: {
          resource: '/contracts-list',
          accessLevel: EPermissionOption.ReadOnly,
        },
      },
      {
        id: 'Grandfathered-Supply-Lateral-Capacity',
        label: 'Grandfathered Supply Lateral Capacity',
        route: '/grandfathered-supply',
        acl: {
          resource: '/contracts',
          accessLevel: EPermissionOption.ReadOnly,
        },
      },
      {
        id: 'Request',
        label: 'Request',
        route: '/contracts',
        acl: {
          resource: '/service-request',
          accessLevel: EPermissionOption.ReadOnly,
        },
      },
      {
        id: 'Request-Internal-',
        label: 'RFS - Deal Sheet',
        route: '/service-requests/admin',
        acl: {
          resource: '/service-requests/admin',
          accessLevel: EPermissionOption.ReadOnly,
          userType: EUserType.Internal,
        },
      },
      {
        id: 'ServiceRequests',
        label: '',
        route: '/service-requests/',
      },
      {
        id: 'DealSheets',
        label: '',
        route: '/deal-sheet/',
      },
      {
        id: 'ContractConsolidations',
        label: '',
        route: '/contract-consolidation/',
      },
    ],
  },
  {
    id: 'marketplace',
    label: 'Marketplace',
    icon: 'icon-short-term-marketplace',
    subMenuItems: [
      {
        id: 'Auctions',
        label: 'Auctions',
        route: '/auctions',
        acl: {
          resource: '/auctions',
          accessLevel: EPermissionOption.Edit,
        },
      },
      {
        id: 'Tradeblotter',
        label: 'Tradeblotter',
        route: '/tradeblotter',
        acl: {
          resource: '/auctions',
          accessLevel: EPermissionOption.Edit,
        },
      },
      // TODO Uncomment to restore ITOnline navigation option
      /*
      {
        id: 'ITOnline',
        label: 'ITOnline',
        route: '/it-online',
        acl: {
          resource: '/auctions',
          accessLevel: EPermissionOption.ReadOnly,
        },
      },
      */
    ],
  },
  {
    id: 'entityManagementSubMenu',
    label: 'Entity Management',
    icon: 'icon-entity',
    subMenuItems: [
      {
        id: 'Entities',
        label: 'Entities',
        route: '/entities',
        acl: {
          resource: '/entities',
          accessLevel: EPermissionOption.ReadOnly,
        },
      },
      {
        id: 'Agents',
        label: 'Agents',
        route: '/agents',
        acl: {
          resource: '/agents',
          accessLevel: EPermissionOption.ReadOnly,
        },
      },
      {
        id: 'Merger-Assignments',
        label: 'Merger/Assignments',
        route: '/merger-assignments',
        acl: {
          resource: '/merger-assignments',
          accessLevel: EPermissionOption.ReadOnly,
          userType: EUserType.Internal,
        },
      },
    ],
  },
  {
    id: 'users-sidebar-item',
    route: '/users',
    label: 'People',
    icon: 'icon-user',
    acl: {
      resource: '/users',
      accessLevel: EPermissionOption.ReadOnly,
    },
  },
  {
    id: 'pipelineModel',
    label: 'Pipeline Model',
    icon: 'icon-pipeline-info',
    subMenuItems: [
      {
        id: 'Pipeline-Model',
        label: 'Pipeline Model',
        route: '/pipeline-model',
        acl: {
          resource: '/pipeline-model',
          accessLevel: EPermissionOption.ReadOnly,
          userType: EUserType.Internal,
        },
      },
      {
        id: 'View-Locations',
        label: 'View Locations',
        route: '/locations/',
        acl: {
          resource: '/locations',
          accessLevel: EPermissionOption.ReadOnly,
          userType: EUserType.Internal,
        },
      },
      {
        id: 'Location-Group-Types',
        label: 'Location Group Types',
        route: '/location-groups',
        acl: {
          resource: '/locations',
          accessLevel: EPermissionOption.ReadOnly,
          userType: EUserType.Internal,
        },
      },
      {
        id: 'Scheduling-Order-Set',
        label: 'Scheduling Order Set',
        route: '/scheduling-order-set',
        acl: {
          resource: '/nominations/scheduling-engine',
          accessLevel: EPermissionOption.Edit,
          userType: EUserType.Internal,
        },
      },
      {
        id: 'Contractual-Rights',
        label: 'Contractual Rights',
        route: '/contractual-rights',
        acl: {
          resource: '/contractual-rights',
          accessLevel: EPermissionOption.ReadOnly,
          userType: EUserType.Internal,
        },
      },
      {
        id: 'Market-Sector-Assignments',
        label: 'Market Sector Assignments',
        route: '/market-sector-assignments',
        acl: {
          resource: '/market-sector-assignments',
          accessLevel: EPermissionOption.ReadOnly,
          userType: EUserType.Internal,
        },
      },
    ],
  },
  {
    id: 'ratesAndDiscounts',
    label: 'Rates',
    icon: 'icon-rates-discounts',
    subMenuItems: [
      {
        id: 'Contractual-Rates',
        label: 'Contractual Rates',
        route: '/contractual-rates',
        acl: {
          resource: '/contractual-rates',
          accessLevel: EPermissionOption.ReadOnly,
          userType: EUserType.Internal,
        },
      },
      {
        id: 'Tariff-Rates',
        label: 'Tariff Rates',
        route: '/tariff-rates',
        acl: {
          resource: '/tariff-rates',
          accessLevel: EPermissionOption.ReadOnly,
          userType: EUserType.Internal,
        },
      },
      {
        id: 'Index-Rate',
        label: 'Index Rates',
        route: '/index-rate',
        acl: {
          resource: '/index-rate',
          accessLevel: EPermissionOption.ReadOnly,
          userType: EUserType.Internal,
        },
      },
      {
        id: 'Charge-Types',
        label: 'Charge Types',
        route: '/charge-types',
        acl: {
          resource: '/charge-types',
          accessLevel: EPermissionOption.ReadOnly,
          userType: EUserType.Internal,
        },
      },
    ],
  },
  {
    id: 'accounting',
    label: 'Accounting',
    icon: 'icon-accounting',
    subMenuItems: [
      {
        id: 'Inventory',
        label: 'Inventory',
        route: '/inventory',
        acl: {
          resource: '/inventory',
          accessLevel: EPermissionOption.ReadOnly,
          userType: EUserType.Internal,
        },
      },
      {
        id: 'Journal-Entries',
        label: 'Journal Entries',
        route: '/journal-entries',
        acl: {
          resource: '/journal-entries',
          accessLevel: EPermissionOption.ReadOnly,
          userType: EUserType.Internal,
        },
      },
      {
        id: 'Adjustments',
        label: 'Adjustments',
        route: '/adjustments',
        acl: {
          resource: '/adjustments',
          accessLevel: EPermissionOption.ReadOnly,
          userType: EUserType.Internal,
        },
      },
      {
        id: 'Projects',
        label: 'Projects',
        route: '/projects',
        acl: {
          resource: '/projects',
          accessLevel: EPermissionOption.ReadOnly,
          userType: EUserType.Internal,
        },
      },
      {
        id: 'Revenue-Recognition',
        label: 'Revenue Recognition',
        route: '/revenue-recognition',
        acl: {
          resource: '/revenue-recognition',
          accessLevel: EPermissionOption.ReadOnly,
          userType: EUserType.Internal,
        },
      },
    ],
  },
  {
    id: 'administration',
    label: 'Administration',
    icon: 'icon-admin',
    subMenuItems: [
      {
        id: 'Accounts',
        label: 'Accounts',
        route: '/accounts',
        acl: {
          resource: '/accounts',
          accessLevel: EPermissionOption.ReadOnly,
          userType: EUserType.Internal,
        },
      },
      {
        id: 'Chart-of-Accounts',
        label: 'Chart of Accounts',
        route: '/chart-of-accounts',
        acl: {
          resource: '/chart-of-accounts',
          accessLevel: EPermissionOption.ReadOnly,
          userType: EUserType.Internal,
        },
      },
      {
        id: 'Cycle-Configuration',
        label: 'Cycle Configuration',
        route: '/cycle-configuration',
        acl: {
          resource: '/cycle-configuration',
          accessLevel: EPermissionOption.ReadOnly,
          userType: EUserType.Internal,
        },
      },
      {
        id: 'informational-postings',
        label: 'Informational Postings',
        acl: {
          resource: '/informational-postings',
          accessLevel: EPermissionOption.ReadOnly,
          userType: EUserType.Internal,
        },
        subMenuItems: [
          {
            id: 'ambient-unsubscribed-capacity',
            label: 'Ambient Unsubscribed Capacity',
            featureName: 'InfoPostAdminAmbient',
            route: '/ambient-unsubscribed-capacity',
            acl: {
              resource: '/ambient-unsubscribed-capacity',
              accessLevel: EPermissionOption.ReadOnly,
              userType: EUserType.Internal,
            },
          },
          {
            id: 'historical-gas-quality',
            label: 'Historical Gas Quality',
            route: '/coastalbendheader/posting-gas-composition-historical',
            acl: {
              resource: '/coastalbendheader/posting-gas-composition-historical',
              accessLevel: EPermissionOption.ReadOnly,
              userType: EUserType.Internal,
            },
          },
          {
            id: 'notice',
            label: 'Notices',
            featureName: 'InfoPostAdminNotices',
            route: '/informational-postings/notice',
            acl: {
              resource: '/informational-postings/notice',
              accessLevel: EPermissionOption.ReadOnly,
              userType: EUserType.Internal,
            },
          },
          {
            id: 'posting',
            label: 'Postings',
            featureName: 'InfoPostAdminPostings',
            route: '/informational-postings/posting',
            acl: {
              resource: '/informational-postings/posting',
              accessLevel: EPermissionOption.ReadOnly,
              userType: EUserType.Internal,
            },
          },
          {
            id: 'scheduling-and-constrained-areas',
            label: 'Scheduling & Constrained Areas',
            featureName: 'InfoPostAdminSchedulingGroup',
            route: '/informational-postings/scheduling-and-constrained-areas',
            acl: {
              resource: '/informational-postings/scheduling-and-constrained-areas',
              accessLevel: EPermissionOption.ReadOnly,
              userType: EUserType.Internal,
            },
          },
        ],
      },
      {
        id: 'Job-Management',
        label: 'Job Management',
        acl: {
          resource: '/job-management/job-configuration', // TODO: Replace this with new entry /job-management
          accessLevel: EPermissionOption.ReadOnly,
          userType: EUserType.Internal,
        },
        subMenuItems: [
          {
            id: 'job-configurations',
            label: 'Job Configurations',
            route: '/job-management/job-configurations',
            acl: {
              resource: '/job-management/job-configurations',
              accessLevel: EPermissionOption.ReadOnly,
              userType: EUserType.Internal,
            },
          },
          {
            id: 'job-logs',
            label: 'Job Logs',
            route: '/job-management/job-logs',
            acl: {
              resource: '/job-management/job-logs',
              accessLevel: EPermissionOption.ReadOnly,
              userType: EUserType.Internal,
            },
          },
        ],
      },
      {
        id: 'Closing-Dashboard',
        label: 'Closing Dashboard',
        route: '/closing-dashboard',
        acl: {
          resource: '/closing-dashboard',
          accessLevel: EPermissionOption.ReadOnly,
          userType: EUserType.Internal,
        },
      },
    ],
  },
  {
    id: 'systemPlanning',
    label: 'System Planning',
    icon: 'icon-system-planning',
    subMenuItems: [
      {
        id: 'average-day-generation',
        label: 'Average Day Generation',
        route: '/system-planning/average-day-generation',
        acl: {
          resource: '/system-planning/average-day-generation',
          accessLevel: EPermissionOption.ReadOnly,
          userType: EUserType.Internal,
        },
      },
      {
        id: 'firm-obligation',
        label: 'Firm Obligation',
        acl: {
          resource: '/system-planning/firm-obligation-export',
          accessLevel: EPermissionOption.ReadOnly,
        },
        subMenuItems: [
          {
            id: 'firm-obligation-export',
            label: 'Firm Obligation Export',
            route: '/system-planning/firm-obligation-export',
            acl: {
              resource: '/system-planning/firm-obligation-export',
              accessLevel: EPermissionOption.ReadOnly,
            },
          },
          {
            id: 'system-planning-fuel-rate',
            label: 'Fuel Rate',
            route: '/system-planning/system-planning-fuel-rate',
            acl: {
              resource: '/system-planning/system-planning-fuel-rate',
              accessLevel: EPermissionOption.Edit,
              userType: EUserType.Internal,
            },
          },
        ],
      },
      {
        id: 'operational-capacity-reservation',
        label: 'Operational Capacity Reservation',
        route: '/system-planning/operational-capacity-reservation',
        acl: {
          resource: '/system-planning/operational-capacity-reservation',
          accessLevel: EPermissionOption.Edit,
        },
      },
      {
        id: 'storage-obligations',
        label: 'Storage Obligations',
        route: '/system-planning/storage-obligations',
        acl: {
          resource: '/system-planning/storage-obligations',
          accessLevel: EPermissionOption.ReadOnly,
        },
      },
    ],
  },
];

export const lowerNavMenuConfigs: NavConfig[] = [
  {
    id: 'info-posts-sidebar-item',
    label: 'Informational Postings',
    icon: 'icon-info',
    skipAclCheck: true,
    route: '/informational-posting',
  },
  {
    id: 'site-map-sidebar-item',
    label: 'Site Map',
    icon: 'icon-sitemap',
    skipAclCheck: true,
    subMenuItems: [
      {
        id: 'boardwalk-storage-site-map',
        label: 'Boardwalk Storage',
        href: 'https://infopost.bwpipelines.com/Frameset.aspx?url=%2FSiteMapPage.aspx&tspid=6',
        skipAclCheck: true,
      },
      {
        id: 'gulf-south-site-map',
        label: 'Gulf South',
        href: 'https://infopost.bwpipelines.com/Frameset.aspx?url=%2FSiteMapPage.aspx&tspid=1',
        skipAclCheck: true,
      },
      {
        id: 'tegas-gas-site-map',
        label: 'Texas Gas',
        href: 'https://infopost.bwpipelines.com/Frameset.aspx?url=%2FSiteMapPage.aspx&tspid=100000',
        skipAclCheck: true,
      },
    ],
  },
];
